<template>
    <div>
        <v-card>
            <v-col>
                <v-data-table
                item-key="LlpID"
                show-select
                dense 
                v-model="selected"
                :headers="headers"
                :items="items"
                ></v-data-table>
            </v-col>
        </v-card>
    
    </div>
</template>
<script>
import _sService from '../../../services/FreshProduction/ComponentPallet/ComponentPallet.js'
import SToolbar from '../../Utils/SToolbar.vue';
export default {
    name:"sPallet",
    components:{SToolbar},
    props:{
        dialogPallet:{type:Boolean, default:false},
        sendData:{type:Boolean, default:false},
    },
    data:()=>({
        items:[],
        headers:[
                {text:"Lote Producción", value:"RcfID", width:70},
                {text:"Calibre", value:"TypeCaliberName", width:70},
                {text:"Destino", value:"TypeDestinyName", width:70},
                {text:"Cultivo", value:"TypeCultiveName", width:70},
                {text:"Cantidad Jabas", value:"LppQuantityJaba", width:70}
            ],
        selected:[]
    }),
    methods: {
        asignarParihuela(){
        },
    },
    mounted() {
        _sService.selectPallet(this.$fun.getUserID())
        .then(resp=>{
            if(resp.status == 200)
            {
                 
                this.items = resp.data;
            }
        })
    },
    watch:{
        'sendData'(){
            this.$emit('emitdata',this.selected)
        }
    }
}
</script>