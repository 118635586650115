<template>
  <div>
    <v-row>
      <v-col lg="12">
        <s-crud
          edit
          title="Registro Canastilla"
          @rowSelected="rowSelected($event)"
          :config="this.config"
          :filter="filter"
          @save="save($event)"
          add
          ref="crud"
          @EditEvent="EditEvent"
        >
          <template v-slot:filter>
            <!-- <v-container>
            <v-row justify="center">
              <v-col cols="12" lg="3">
                <s-select-definition label="Estado Canastilla" v-model="filter.LayStatus" :def="1263"/>
              </v-col>
              <v-col cols="12" lg="3">
                <s-select-definition label="Número Canastilla" v-model="filter.LayNumberLayette" :def="1259"/>
              </v-col>
            </v-row>
          </v-container> -->
          </template>
          <template scope="props">
            <v-container v-if="props.item != null" class="pb-0">
              <v-row>
                <!-- <v-col lg="2" class="s-col-form" cols="12" sm="4" md="4">
                  <s-text label="Nro Proceso" v-model="props.item.LayNumberProcess" />
                </v-col> -->
                <v-col lg="3" class="s-col-form" cols="12" sm="4" md="4">
                  <s-select-definition
                    label="Nro Canastilla"
                    :def="1259"
                    v-model="props.item.LayNumberLayette"
                  />
                </v-col>
                <v-col lg="3" class="s-col-form" cols="12" sm="4" md="4">
                  <s-select-definition
                    label="Estado Canastilla"
                    :def="1263"
                    v-model="props.item.LayStatus"
                  />
                </v-col>
                <v-col lg="3" class="s-col-form" cols="12" sm="4" md="4">
                  <s-select-definition
                    label="Frente Canastilla"
                    :def="1260"
                    v-model="props.item.TypeFrontLayette"
                  />
                </v-col>
                <v-col lg="3" class="s-col-form" cols="12" sm="4" md="4">
                  <s-select-definition
                    label="Tiempo de Hidrotermico"
                    :def="1261"
                    v-model="props.item.TypeTime"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col class="mt-5">
                  <table-layette
                    v-model="itemsLayette"
                    :items="rowsLay"
                    @emitdata="emitdata($event)"
                    @dataItemsPallet="dataItemsPallet"
                  >
                  </table-layette>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:btnProcesar="{ row }">
            <v-btn
              x-small
              @click="showModalLayette"
              icon
              color="light-blue darken-4"
            >
              <v-icon style="font-size: 20px !important">
                mdi mdi-chevron-double-right
              </v-icon>
            </v-btn>
          </template>
        </s-crud>
        <s-layette
          :dialogLayette="dialogLayette"
          :layetteRow="layetteRow"
          @hideModal="hideModalLayette"
          @closeLayette="closeLayette"
        >
        </s-layette>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import _sCreateLayette from "@/services/FreshProduction/CreateLayetteService";
import SSelectBoarding from "@/components/FreshProduction/LotProduction/SSelectBoardingSchedule";
import SSelectVariety from "@/components/HarvestSchedule/SSelectVariety";
import SSelectCaliber from "@/components/FreshProduction/CreateLayette/sSelectCaliber";
import SSLayette from "@/components/FreshProduction/CreateLayette/sSelectCaliber";
import SSelectLayette from "@/components/FreshProduction/CreateLayette/sLayette";
import SLayette from "../../../components/FreshProduction/CreateLayette/sLayette.vue";
import TableLayette from "@/views/FreshProduction/CreateLayette/TableLayette";
export default {
  components: {
    SSelectBoarding,
    SSelectVariety,
    SSelectCaliber,
    SSLayette,
    SSelectLayette,
    SLayette,
    TableLayette,
  },
  data: () => ({
    filter: { LayNumberLayette: 0 },
    layetteRow: [],
    obtieneLayID:null,
    isEdit:false,
    itemsLayette: [],
    rowsLayette: [],
    rowsLay: [],
    dialogLayette: false,
    config: {
      model: {
        LayID: "ID",
        btnProcesar: "",
      },
      service: _sCreateLayette,
      headers: [
        { text: "Número Proceso", value: "LayID", width: 70 },
        {
          text: "Número Canastilla",
          value: "LayNumberLayetteText",
          width: 100,
        },
        {
          text: "Frente Canastilla",
          value: "TypeFrontLayetteText",
          width: 100,
        },
        { text: "Tiempo Hidrotérimico", value: "TypeTimeText", width: 100 },
        // { text: "Tipo Cultivo", value: "TypeCropName", width:100 },
        // { text: "Variedad", value: "VrtIDName", width:120 },
        { text: "Procesar", value: "btnProcesar", width: 50, align: "center" },
      ],
    },
  }),
  methods: {
    rowSelected(item) {
      if(item.length>0)
      {
        this.layetteRow=item[0]
        this.obtieneLayID=item[0].LayID;
      }
    },
    emitdata(item) {
      this.rowsLayette = item;
    },
    Initialize() {},
    closeLayette() {
      this.dialogLayette = false;
    },
    save(item) {
      //  this.itemsLayette.forEach((element) => {
      //    if(!element.RcfID){
      //      this.$fun.alert("Ingrese Pallet", "warning");
      //      return;
      //    }
      //  });
      if(this.isEdit)
      {
        item.itemsLayette=this.rowsLay;
      }
      if (this.itemsLayette.length == 0) {
        this.$fun.alert("Ingresar Pallets", "warning");
        return;
      }
      item.CreateLayetteDetail = this.itemsLayette;
      item.UsrCreateID = this.$fun.getUserID();
      item.save();
      //this.$refs.crud.refresh();
    },
    validationForm() {
      return true;
    },
    showModalLayette() {
      this.dialogLayette = true;
    },
    hideModalLayette() {
      this.dialogLayette = false;
    },
    EditEvent() {
      this.isEdit=true;
      _sCreateLayette
        .llenarParihuela(this.obtieneLayID, this.$fun.getUserID())
        .then((response) => {
          let lays = response.data;
          this.rowsLay = response.data;
          this.itemsLayette= response.data;
        });
    },
    dataItemsPallet(dataItemsPallet) {
      this.itemsLayette = dataItemsPallet;
    },
  },
};
</script>