import Service from "../../Service";
const resource = "selectPallet/"

export default{
    selectPallet(requestID) {
        return Service.post(resource + "selectPallet", {}, {
            params: { requestID }
        });
    },
    //Para llenar la grilla y editar
    llenarParihuela(LayID){
        return Service.post(resource + "listParihuela",{},{
            params:{LayID:LayID}
        });
    }
}
