<template>
    <div>
        <v-dialog v-model="dialogLayette" width="810" persistent>
            <v-card>
                <v-col>
                        <s-crud
                        no-border
                        title="Procesar Canastilla"
                        :config="this.config"
                        :filter="filter"
                        @close="closeLayette()"
                        add
                        close
                        edit
                        sortable
                        noConfimationSave
                        @save="save($event)"
                        formPermanent
                        >
                        <template scope="props">
                            <v-container v-if="props.item != null" class="pb-0">
                                <v-row>
                                    <v-col lg="3" class="s-col-form">
                                        <s-text disabled label="Número Proceso" v-model="PlyNumberProcess"/>
                                    </v-col>
                                    <v-col lg="3" class="s-col-form">
                                        <s-select-definition :def="1269" label="Línea Proceso" v-model="props.item.TypeLineProcess"></s-select-definition>
                                    </v-col>
                                    <v-col class="s-col-form" lg="3">
                                        <s-select-definition :def="1249" label="Tanque" v-model="props.item.TypeTank"></s-select-definition>
                                    </v-col>
                                    <v-col lg="3" class="s-col-form">
                                        <s-text label="Posición Tanque" v-model="props.item.PlyPositionTank"></s-text>
                                    </v-col>
                                    <v-col lg="3" class="s-col-form" cols="12" sm="4" md="4">
                                        <s-select-definition label="Número Canastilla" :def="1259" v-model="props.item.TypeNumberLayette" />
                                    </v-col>
                                    <v-col lg="3" class="s-col-form" cols="12" sm="4" md="4">
                                        <s-select-definition label="Tiempo de Hidrotermico" :def="1261" v-model="props.item.TypeTimeHydrothermal"/>
                                     </v-col>
                                     <v-col lg="3" class="s-col-form" cols="12" sm="4" md="4">
                                         <s-text label="Categoría" ></s-text>
                                    </v-col >
                                    <v-col lg="3" class="s-col-form">
                                        <s-text number label="Número Tratamiento" v-model="props.item.PlyNumberTreatment"></s-text>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col lg="3" class="s-col-form">
                                        <s-time v-model="props.item.PlyTimeBegin" label="Hora Entrada"></s-time>
                                    </v-col>
                                    
                                    <v-col lg="3" class="s-col-form">
                                        <s-select-definition :def="1181" label="Destino" v-model="props.item.TypeDestiny"></s-select-definition>
                                    </v-col>
                                    <v-col lg="3" class="s-col-form">
                                        <s-select-definition label="Estado" :def="1268" v-model="props.item.PlyStatus"></s-select-definition>
                                    </v-col>
                                    <!-- <v-col lg="3" class="s-col-form">
                                        <s-text label="Prueba" v-model="test"></s-text>
                                    </v-col> -->
                                </v-row>
                            </v-container>
                        </template>
                        </s-crud>
                    </v-col>
            </v-card>
        </v-dialog>
    </div>
</template>
<script>
import _sService from '@/services/FreshProduction/ProcessLayette'
import SText from '../../Utils/SText.vue';
import STime from '../../Utils/STime.vue';
import SSelectDefinition from '../../Utils/SSelectDefinition.vue';

export default {
    name:"sLayette",
    components:{SText, STime, SSelectDefinition},
    props:{
        dialogLayette:{type:Boolean, default:false},
        layetteRow: {}
    },
    data:()=>({
        test: "",
        PlyNumberProcess:"",
        filter:{},
        config:{
        title:"Config",
        model:{
            PlyID: "ID"
        },
        service:_sService,
        headers:[
            {text:"ID", value:"PlyID"},
            {text:"Tanque", value:"TypeTankName"},
            {text:"Número Tanque", value:"TypeNumberLayetteName"},
            {text:"Frente Tanque", value:"TypeFrontLayetteName"},
            {text:"Tiempo Hidrotermico", value:"TypeTimeHydrothermalName"},
            {text:"Calidad", value:"TypeQualityName"}
        ]
    },
    }),
    methods:{
        Initialize(){
            this.filter={PlyID: this.PlyID};
        },
        closeLayette(){
            this.$emit("closeLayette")
        },
        save(item){
            item.PlyNumberProcess = this.PlyNumberProcess;
            if(this.validationForm(item)) 
            item.save();
        },
        validationForm(){
            return true;
        }
    },
    watch: {
        layetteRow(){
            if(this.layetteRow != null)
            {
                this.PlyNumberProcess = this.layetteRow.LayID;
            }
        },
    }
}
</script>