import Service from "../Service";
import Vue from "vue";
const resource = "listCaliber/"

export default{
    listCaliber(requestID){
        alert()
        return Service.post(resource + "listCaliber",{},{
            params:{requestID:requestID}
        });
    },
    GetlistCaliber(){
        return Vue.prototype.$http.defaults.baseURL+"/listCaliber/listCaliber"
        
    }
}
