<template>
    <div>
        <v-card>
            <v-row>
                <v-col class="pb-0 pt-0">
                    <s-toolbar
                    remove
                    add
                    :elevation="2"
                    label="Asignar Parihuelas"
                    @add="selectPallet"
                    @removed="remove()"
                    ></s-toolbar>
                </v-col>
            </v-row>
            <v-col>
            <v-row justify="rigth" >
                <v-col class="pt-5"  >
                    <v-data-table
                    dense
                    show-select
                    item-key="RcfID"
                    v-model="itemParihuela"
                    :items="selectParahuela"
                    :headers="headers"
                    ></v-data-table>
                </v-col>
            </v-row>
            </v-col>
        </v-card>
       <!--------------------------- SÓLO PARA EL MODAL ----------------------------->
       <v-dialog v-model="dialogPallet" width="600" persistent>
        <s-toolbar
                    close 
                    @add="asignarParihuela" 
                    add
                    :elevation="2" 
                    label="Crear Canastilla" 
                    @close="close" 
                    @removed="removed()">
                    </s-toolbar>
        <s-select-pallet 
        @close="closePallet"
        :sendData="sendData"
        @emitdata='emitdata($event)'
        ></s-select-pallet>
    </v-dialog>
    </div>
</template>
<script>
import SSelectPallet from '../../../components/FreshProduction/CreateLayette/sSelectPallet.vue'
export default {
    components:{
        SSelectPallet
    },
    props:{
        items: null
    },
    data:()=>({
        sendData:false,
        selectParahuela:[],
        itemParihuela:[],
        headers:[
            {text:"Lote Producción", value:"RcfID", width:70},
            {text:"Calibre", value:"TypeCaliberName", width:70},
            {text:"Destino", value:"TypeDestinyName", width:70},
            {text:"Cultivo", value:"TypeCultiveName", width:70},
            {text:"Cantidad Jabas", value:"LppQuantityJaba", width:70}
        ],
        dialogPallet:false,
    }),
    watch: {
        items(){
            this.selectParahuela = this.items;
        }
    },

    methods: {
        asignarParihuela(){
            this.sendData=true
            this.close();
        },
        close(){
            this.dialogPallet=false;
        },
        selectPallet(){
            this.dialogPallet=true;
        },
        closePallet(){
            this.dialogPallet=false;
        },
        emitdata(item){
            this.selectParahuela=item;
            this.$emit("dataItemsPallet", this.selectParahuela);
        },
        remove(){
            this.itemParihuela.forEach((element) => {
                this.selectParahuela = this.selectParahuela.filter(
                    (x) => x.RcfID != element.RcfID
                );
            });
        },
    },
}
</script>
